import React, { Component } from 'react';
import { PageTitle } from "gg-react-utilities";
import style from "../../../../../node_modules/blueprint-css/dist/blueprint.css";
import banner_1 from "../../../../images/banner.png";
import Pricing from './Pricing';
import Switch from "react-switch";
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { MyCards , MyOrders} from '../../../../components/SortCutIcons';
import Config from "../../../../Config";
import { Button } from '@material-ui/core';
import {Intent, Position, Toaster} from "@blueprintjs/core";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardList:[],
            cardType:false,
            loader:false,
            waitlistLoader:false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        this.setState({
            loader:true
        })
        this.props.getPrepaidCardList(this.cardType());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            cardList:nextProps.prepaidCardList,
            loader:false
        })
    }

    handleChange(cardType) {
        this.setState({
           cardType:cardType,
            loader:true
        },()=>{
                this.props.getPrepaidCardList(this.cardType());
        });
    }

    cardType = () => {
        return this.state.cardType ? 0 : 1
    }

    joinWaitlist = () =>{

        this.setState({
            waitlistLoader:true
        })

        this.props.setWaitList()
            .then((res) => {

                let resource = res.data;

                if(resource.status === true) {
                    Toaster.create({position: Position.TOP}).show({
                        message: resource.message,
                        intent: Intent.SUCCESS
                    });
                }else{
                    Toaster.create({position: Position.TOP}).show({
                        message: resource.message,
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    waitlistLoader:false
                })
            })
            .catch((error)=>{
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    waitlistLoader:false
                })
            });

    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <PageTitle title="Home | Global Garner | Prepaid Card" />
                </div>

                <div className="thyro_banner">
                    <img src={banner_1} alt="Banner 1" />
                </div>

                <div bp="grid padding-top ">
                    <div bp="12 text-center">
                        <h1>Join the waitlist to avail your physical prepaid card at the earliest</h1>
                        <div>
                            {
                                !this.state.waitlistLoader ?
                                    <div className="odr_detail_btn">
                                        <Button className='btn' onClick={()=>{this.joinWaitlist()}}>Join Waitlist</Button>
                                    </div>
                                :
                                    <Loader/>
                            }
                        </div>

                        <span>Note: Forms will be closed on 5th Feb 2023</span>
                    </div>
                </div>

                <div bp="grid padding-top">
                    <div bp="12 text-center">
                        <h1>Digital Prepaid Card</h1>
                    </div>
                </div>
                {
                    !this.state.loader ?
                        <React.Fragment>
                        {/*<div bp="grid text-center padding-bottom flex">
                            <div className="Switch" bp="12">
                                <h2>Digital</h2>
                                <label>
                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={this.handleChange} checked={this.state.cardType} />
                                </label>
                                <h2>Physical</h2>
                            </div>
                        </div>*/}

                        <div className='container mb_30' bp="grid padding-left padding-right">
                            {
                                this.state.cardList && this.state.cardList.length ?
                                    this.state.cardList.map((list,key)=>{
                                        return(
                                            <div bp="3@lg 6@md 12@sm" key={key}>
                                                <Link style={{"text-decoration": "none", "color": "#212529"}}
                                                      to={'/card-detail/' + list.id +'/' + list.is_digital}>
                                                        <Pricing
                                                            cardDetails={list} />
                                                </Link>
                                            </div>
                                        )
                                    })
                                :''
                            }
                        </div>
                        </React.Fragment>
                    : <Loader />
                }

                <MyCards />

                <MyOrders />


            </React.Fragment>
        );
    }
}