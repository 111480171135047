import React, { Component } from 'react';
// import Card from "../../../../images/Card.png";
import Card from "../../../../images/order_card_wb.png";
import { Button } from '@material-ui/core';
import TemporaryDrawer from './Sidebar';

export default class MyCard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.cardDetails ?
                        <div bp="4@lg 6@md">
                        <div className='MyCard'>

                            {/* <img src={Card} alt='Card' /> */}

                            <figure class="textover">
                                <img src={Card} alt='Card'/>
                                <figcaption className='card_type'>{this.props.cardDetails.card_name}</figcaption>
                                <figcaption>XXXX XXXX XXXX XXXX</figcaption>
                                <figcaption className='card_sd'>{this.props.cardDetails.valid_from}</figcaption>
                                <figcaption className='card_ed'>{this.props.cardDetails.expired_date}</figcaption>
                            </figure>


                            <div className='card_detail' bp="grid">
                                <div bp="6 text-left">
                                    <p>Rs. {this.props.cardDetails.current_balance}/-</p>
                                </div>
                                <div bp="6 text-right">
                                    {
                                        this.props.cardDetails.card_status_show == 2 ?
                                            <p><a href={this.props.cardDetails.card_link} target="_blank">{this.props.cardDetails.card_status_text}</a></p>
                                        :
                                            <p>{this.props.cardDetails.card_status_text}</p>
                                    }
                                </div>
                                <div bp="12 text-center">
                                    <p>Card Id : {this.props.cardDetails.order_id}</p>
                                </div>
                                <div bp="12 text-center">
                                    <TemporaryDrawer cardDetails={this.props.cardDetails}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :''
                }
            </React.Fragment>
        )
    }
}
