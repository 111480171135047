import React, { Component } from 'react';
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import Table from 'react-bootstrap/Table';
import Loader from '../../../../components/Loader';
import OrderDetails from '../container/OrderDetails';
import { MyCards } from '../../../../components/SortCutIcons';

export default class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prepaidOrderList: [],
      pageLoader: false,
      loader: false,
      page: 1
    }
  }

  componentDidMount() {
    this.setState({
      loader: true
    })

    this.props.getPrepaidCardOrderList(this.state.page);
  }

  componentWillReceiveProps(nextProps, nextContext) {

    let oldAffData = this.state.prepaidOrderList;
    let newAffDataFromApi = nextProps.prepaidCardOrderList.data;
    let newAffData = oldAffData.concat(newAffDataFromApi);

    let stopLoadMore = false;
    if (nextProps.prepaidCardOrderList.last_page == this.state.page) {
      stopLoadMore = true;
    }

    this.setState({
      prepaidOrderList: newAffData,
      loader: false,
      pageLoader: false,
      stopLoadMore: stopLoadMore
    })
  }

  handleScroll = () => {

    var allBooking = document.getElementById('allBooking');
    var allBookingHeight = (allBooking.scrollHeight) - (allBooking.scrollTop + 500);
    if (parseInt(allBookingHeight) === 0 && this.state.stopLoadMore === false) {
      this.NextPage()
    }
  }

  NextPage = () => {
    let oldPageNo = this.state.page;
    this.setState({
      page: oldPageNo + 1,
      pageLoader: true
    }, () => {

      this.props.getPrepaidCardOrderList(this.state.page)
    })

  }

  render() {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='TransactionHistory_header' bp="grid vertical-center">

            <div bp="10">
              <PageTitle title="Transaction History | Global Garner | Prepaid Card" />
              <TopBar title="Orders History" backUrl="/" {...this.props} />
            </div>

          </div>

          <div bp="12">

            <div className='Transaction_History'>


              {
                !this.state.loader ?
                  <div style={{ height: '100%', maxHeight: '500px', overflowY: 'scroll' }} id={"allBooking"} onScroll={this.handleScroll}>
                    <Table responsive="sm">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Card Name</th>
                          <th>Grand Total</th>
                          <th>Order Status</th>
                          <th>Caseback Choice</th>
                          <th>UPV Status</th>
                          <th>Created At</th>
                          <th>OrderDetails</th>
                        </tr>
                      </thead>
                      <tbody className='mt_20'>
                        {
                          this.state.prepaidOrderList && this.state.prepaidOrderList.length ?
                            this.state.prepaidOrderList.map((data, key) => {
                              return (
                                <tr key={key}>
                                  <td>{data.order_id}</td>
                                  <td>{data.card_name}</td>
                                  <td>Rs.{data.grand_total}/-</td>
                                  <td>{data.order_status_text}</td>
                                  <td>{data.caseback_choice_text}</td>
                                  <td>{data.upv_status_text}</td>
                                  <td>{data.created_date}</td>
                                  <td><OrderDetails orderId={data.order_id} /></td>
                                </tr>

                              )
                            })
                            :
                              <tr>
                                <td colSpan="8" style={{height:"460px"}}>
                                    <center><h3>Your Order is empty.</h3></center>
                                </td>
                              </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                  :
                  <Loader />
              }


            </div>

          </div>

        </div>

        <MyCards />
      </React.Fragment>
    )
  }
}