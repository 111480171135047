import React from 'react';
import {Dialog} from "@blueprintjs/core";
import {GgUtils, Error} from "gg-react-utilities";
import { Button } from '@material-ui/core';

export default class index extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        try {
            let slug = this.props.match.params.status ? this.props.match.params.status : "";

            let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");

            document.title = "Order Place";

            let title = "";
            let message = "";
            //let link = "";
            let image = "";
            let redirectLink = "";


            if (slug === "success") {

                message = query.get('msg');

                title = query.has('title') ? query.get('title') : "Your Order is Successfully Placed";

                //link = <Link to="/voucher-history" className="pb-4">View Order History</Link>;
                image = require('../../../images/success.svg');
            }else if (slug === "failed") {
                title = query.has('title') ? query.get('title') : "Your Order Can Not be Placed";
                message = query.get('msg');
                //link = <Link to="/vouchers=" className="pb-4">Go To Home</Link>;
                image = require('../../../images/cancel.svg');
            }else{
                window.location.href = '/';
            }

            if(query.has('loadbalance')){
                redirectLink = "/transaction-history/"+query.get('orderid');
            }else{
                redirectLink = "/orders";
            }

            /*redirectLink = "/orders";



            setTimeout(() => {
                window.location.href = redirectLink;
            }, 5000);
*/
            return (
                <React.Fragment>
                    <div className={"mh-100"}>
                        <Dialog
                            isOpen={true}
                            lazy={false}
                            className="bp3-dialog-large payment-status"
                            style={{width :"60% !important",left: "30% !important"}}
                            canOutsideClickClose={false}
                        >
                            <div className="mg-brand-payments">
                                <div className="">
                                    <div className="">
                                        <div className="">
                                            <div className="paymentWrapper">
                                                <div className="upperPaymentWrapper"></div>
                                                <div className="middlePaymentWrapper">
                                                    <div className="circleWrapper">
                                                        <div className="iconWrapper">
                                                            <img src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div bp="margin-top" className="col-8 lowerPaymentWrapper m-auto pb-4">
                                                    <h3>{title}</h3>
                                                    <p>{message}</p>
                                                    <div className="odr_detail_btn">
                                                        <center>
                                                            <Button className='btn ' onClick={()=>{
                                                                window.location.href = redirectLink;
                                                            }}>Go To Order</Button>
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </React.Fragment>
            )
        } catch (e) {
            return (
                <Error/>
            )
        }
    }
}
