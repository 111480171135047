import React, { Component } from 'react';
import { styles } from "../../../../../src/style/prepaid-card/prepaid_card.css";
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import style from "../../../../../node_modules/blueprint-css/dist/blueprint.css";
import banner_1 from "../../../../images/premium_card.svg";
import { Button } from '@material-ui/core';
import ControlledRadioButtonsGroup from './Select_card';
import Faq from './Faq';
import Loader from '../../../../components/Loader';
import {RadioGroup, Radio, Tab, Tabs} from "@blueprintjs/core";
import Config from '../../../../Config';
import { MyCards , MyOrders} from '../../../../components/SortCutIcons';

export default class Card_Detail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cardDetails:{},
            loader:false
        }
    }
    
    componentDidMount() {
        let obj = {
            id:this.props.match.params.cardId,
            is_digital:this.props.match.params.cardType
        }

        this.setState({
            loader:true
        })
        this.props.getPrepaidCardDetails(obj);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps.prepaidCardDetails);
        this.setState({
            cardDetails:nextProps.prepaidCardDetails,
            loader:false
        })
    }
    
    render() {
        return (
            <React.Fragment>
                <div className='container'>

                    <PageTitle title="Card Details | Global Garner | Prepaid Card" />
                    <TopBar title="Card Details" {...this.props} />

                    <div bp="grid padding-bottom">
                        <div bp="5@lg 5@md">
                            <div className="thyro_banner">
                                <img src={banner_1} alt="Banner 1" />
                            </div>
                        </div>
                        <div bp="7@lg 7@md">
                            {
                                !this.state.loader ?
                                    this.state.cardDetails && this.state.cardDetails.id ?
                                        <div className='Detail-sidebar'>
                                            <h2>{this.state.cardDetails.categoryName}</h2>
                                            <RadioGroup className='d-flex mt_10' selectedValue={this.state.cardDetails.is_digital == 1 ? 'Digital' : 'Physical'}>
                                                <Radio label={this.state.cardDetails.is_digital == 1 ? 'Digital' : 'Physical'} value={this.state.cardDetails.is_digital == 1 ? 'Digital' : 'Physical'} />
                                            </RadioGroup>
                                            <h2><strong>Rs. {this.state.cardDetails.card_price}/-</strong></h2>
                                            <p>Inclusive of all taxes</p>

                                            <div className='Detail_shad'>
                                                <h3>key Features</h3>
                                                <div bp="grid margin-bottom">
                                                    <div bp="12">
                                                        <ul>
                                                            {
                                                                this.state.cardDetails.keyFeature.map((data, key) => {
                                                                    return (
                                                                        <li>
                                                                            <p>{data.title}</p>
                                                                            <p>{data.value}</p>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <h3>Card Specifications</h3>
                                                <div bp="grid gap-none margin-bottom">
                                                    <div bp="12">
                                                        <ul>
                                                            {
                                                                this.state.cardDetails.cardSpecifications.map((data, key) => {
                                                                    return (
                                                                        <li>
                                                                            <p>{data.title}</p>
                                                                            <p>{data.value}</p>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <Button
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    window.location.href=Config.APP_URL+"/checkout/" + this.state.cardDetails.id + '/' + this.state.cardDetails.is_digital;
                                                }}
                                                className='btn'>Buy Now</Button>
                                </div>
                                    :''
                                :
                                    <Loader />
                            }
                        </div>
                        <div bp="12@lg 12@md" className="prepaidcard-details">
                            {
                                this.state.cardDetails && this.state.cardDetails.id ?
                                    <Tabs
                                        animate={true}
                                        id="TabsDetails"
                                        key="horizontal"
                                        renderActiveTabPanelOnly={true}
                                        vertical={false}
                                        className="bd-tabs"
                                    >
                                        <Tab className="bd-tab" id="tab1" title="Product Description"
                                             panel={<div className="tab-content"
                                                         dangerouslySetInnerHTML={{__html: this.state.cardDetails.card_description}}></div>}/>
                                        <Tab className="bd-tab" id="tab2" title="How To Redeem"
                                             panel={<div className="tab-content"
                                                         dangerouslySetInnerHTML={{__html: this.state.cardDetails.how_to_redeem}}></div>}/>
                                        <Tab className="bd-tab" id="tab3" title="Terms & Conditions"
                                             panel={<div className="tab-content"
                                                         dangerouslySetInnerHTML={{__html: this.state.cardDetails.card_tnc}}></div>}/>
                                    </Tabs>
                                    :''
                            }
                        </div>
                    </div>

                    {/*<div className='faq' bp="grid 12 padding-bottom">
                        <h1><strong>FAQ</strong></h1>
                        <Faq />
                    </div>*/}
                </div>

                <MyOrders />

                <MyCards />

            </React.Fragment>
        );
    }
}