import {connect} from "react-redux";
import {getPrepaidCardList,setWaitList} from "../../../../actions/action";
import {PrepaidCardListAction} from "../../../../actions";
import Home from '../component/HomeComponent';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
        prepaidCardList:state.prepaidCard.prepaidCardList,
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getPrepaidCardList: (cardType) =>{
            return getPrepaidCardList(cardType)
                .then((res) => {

                    let resource = res.data;

                    if(resource.status === true) {
                        dispatch(PrepaidCardListAction(resource.data))
                    }else{
                        dispatch(PrepaidCardListAction([]))
                    }
                })
                .catch((error)=>{
                    if (401 !== error.response.status) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
                });
        },
        setWaitList: () =>{
            return setWaitList();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)