import {connect} from "react-redux";
import {getPrepaidCardOrderList} from "../../../../actions/action";
import {PrepaidCardOrderListAction} from "../../../../actions";
import OrderHistory from '../component/OrderHistory';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
        prepaidCardOrderList:state.prepaidCard.prepaidCardOrderList,
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getPrepaidCardOrderList: (page) =>{
            return getPrepaidCardOrderList(page)
                .then((res) => {

                    let resource = res.data;

                    if(resource.status === true) {
                        dispatch(PrepaidCardOrderListAction(resource.data))
                    }
                })
                .catch((error)=>{
                    if (401 !== error.response.status) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
                });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)