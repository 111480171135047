
export const PrepaidCardListAction = (sources) => {
    return {
        type: 'PREPAID_CARD_LIST',
        sources:sources
    }
}

export const PrepaidCardDetailsAction = (sources) => {
    return {
        type: 'PREPAID_CARD_DETAILS',
        sources:sources
    }
}

export const PrepaidCardOrderListAction = (sources) => {
    return {
        type: 'PREPAID_CARD_ORDER_LIST',
        sources:sources
    }
}

export const MyCardListAction = (sources) => {
    return {
        type: 'MY_CARD_LIST',
        sources:sources
    }
}

export const TrasactionListAction = (sources) => {
    return {
        type: 'TRASACTION_LIST',
        sources:sources
    }
}
