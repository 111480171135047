import React, { Component } from 'react';
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import Table from 'react-bootstrap/Table';
import Loader from '../../../../components/Loader';

export default class TransactionHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trasactionList: [],
      pageLoader: false,
      loader: false,
      page: 1
    }
  }

  componentDidMount() {
    this.setState({
      loader: true
    })

    this.props.getTrasactionHistory(this.props.match.params.orderId, this.state.page);
  }

  componentWillReceiveProps(nextProps, nextContext) {

    let oldAffData = this.state.trasactionList;
    let newAffDataFromApi = nextProps.trasactionList.data;
    let newAffData = oldAffData.concat(newAffDataFromApi);

    let stopLoadMore = false;
    if (nextProps.trasactionList.last_page == this.state.page) {
      stopLoadMore = true;
    }

    this.setState({
      trasactionList: newAffData,
      loader: false,
      pageLoader: false,
      stopLoadMore: stopLoadMore
    })
  }

  handleScroll = () => {

    var allBooking = document.getElementById('allBooking');
    var allBookingHeight = (allBooking.scrollHeight) - (allBooking.scrollTop + 500);
    console.log(allBookingHeight)
    if (parseInt(allBookingHeight) === 0 && this.state.stopLoadMore === false) {
      this.NextPage()
    }
  }

  NextPage = () => {
    let oldPageNo = this.state.page;
    this.setState({
      page: oldPageNo + 1,
      pageLoader: true
    }, () => {
      let params = {
        start: this.state.start
      }
      this.props.getTrasactionHistory(this.props.match.params.orderId, this.state.page)
    })

  }

  render() {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='TransactionHistory_header' bp="grid vertical-center">

            <div bp="10">
              <PageTitle title="Transaction History | Global Garner | Prepaid Card" />
              <TopBar title="Transaction History" backUrl="/my-card" {...this.props} />
            </div>

            {/*<div bp="2 text-right">
            <h2><a href='#'><i class="fa fa-download"></i></a></h2>
          </div>*/}

          </div>

          <div bp="12">
            <div className='Transaction_History'>

              {
                !this.state.loader ?
                <div style={{height: '100%', maxHeight: '500px', overflowY: 'scroll'}} id={"allBooking"} onScroll={this.handleScroll}>  
                <Table responsive="sm" >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className='mt_20'>
                      {
                        this.state.trasactionList && this.state.trasactionList.length ?
                          this.state.trasactionList.map((data, key) => {
                            return (
                              <tr key={key}>
                                <td>{data.unique_id}</td>
                                <td>{data.title}</td>
                                <td>{data.date}</td>
                                <td>{data.time}</td>
                                <td>Rs. {data.amount}/-</td>
                                <td style={{ color: data.order_status_color }}>{data.order_status_text}</td>
                              </tr>
                            )
                          })
                          :
                            <tr>
                              <td colSpan="6" style={{height:"215px"}}>
                                <center><h3>Your Trasaction History is empty.</h3></center>
                              </td>
                            </tr>
                      }
                    </tbody>
                  </Table>
                  </div>
                  :
                  <Loader />

              }

            </div>
          </div>

        </div>
      </React.Fragment>
    )
  }
}