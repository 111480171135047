let initialState = {
    prepaidCardList: [],
    prepaidCardDetails: {},
    prepaidCardOrderList: [],
    myCardList: [],
    trasactionList: [],
};
const prepaidCard = (state = initialState, action) => {

    switch (action.type) {
        
        case 'PREPAID_CARD_LIST':
            return {...state, prepaidCardList: action.sources}
        case 'PREPAID_CARD_DETAILS':
            return {...state, prepaidCardDetails: action.sources}
        case 'PREPAID_CARD_ORDER_LIST':
            return {...state, prepaidCardOrderList: action.sources}
        case 'MY_CARD_LIST':
            return {...state, myCardList: action.sources}
        case 'TRASACTION_LIST':
            return {...state, trasactionList: action.sources}
        default:
            return state
    }
}

export default prepaidCard
