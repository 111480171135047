import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import '../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import styles from '../src/style/prepaid-card/prepaid_card.css';
// import "./style/gg-styles.css";

import reducers from './reducers';
import App from './App';
import {Notfound,Maintance,ScrollTop,GgUtils} from 'gg-react-utilities';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import Home from './pages/Prepaid_Card/HomePage/Index';
import Card_Detail from './pages/Prepaid_Card/DetailPage/Index';
import Checkout_index from './pages/Prepaid_Card/Checkout';
import Profile from './pages/Prepaid_Card/Profile';
import Balance from './pages/Prepaid_Card/Profile/container/Balance';
import OrderDetail from './pages/Prepaid_Card/Profile/container/OrderDetails';
import TransactionHistory from './pages/Prepaid_Card/Profile/container/TrasactionHistory';
import OrderHistory from './pages/Prepaid_Card/OrderHistory/index';
import Payments from './pages/Prepaid_Card/payment/index';

const customMiddleWare = store => next => action => {
    next(action)
}

// Auth.logout()
const createStoreWithMiddleware = applyMiddleware(customMiddleWare)(createStore);

if (process.env.REACT_APP_ENV === 'PRODUCTION'){

    GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_URL);

}

if (process.env.REACT_APP_ENV === 'PRODUCTION'){
    GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
}

ReactDOM.render(
    <React.Fragment>

        <Provider store={createStoreWithMiddleware(reducers)}>
            <BrowserRouter>
              <App>
                  <div className="flight">
                      {
                        process.env.REACT_APP_IS_MAINTENANCE_MODE===true || process.env.REACT_APP_IS_MAINTENANCE_MODE==="true"?
                        <Switch>
                          <Route path="*" component={Maintance}/>
                        </Switch>
                        :
                        <Switch>
                          <Route exact path='/' component={Profile}/>
                          <Route exact path='/my-card/:isHome?' component={Profile}/>
                          <Route exact path='/home' component={Home}/>
                          <Route exact path='/card-detail/:cardId/:cardType?' component={Card_Detail}/>
                          <Route exact path='/checkout/:cardId/:cardType' component={Checkout_index}/>
                          <Route exact path="/payment/:status" component={Payments}/>
                          <Route exact path='/order-detail/:orderId' component={OrderDetail}/>
                          <Route exact path='/balance/:orderId' component={Balance}/>
                          <Route exact path='/orders' component={OrderHistory}/>
                          <Route exact path='/transaction-history/:orderId' component={TransactionHistory}/>
                          <Route path="*" component={Notfound}/>
                        </Switch>
                      }
                  </div>
                  <ScrollTop/>
                </App>
            </BrowserRouter>
        </Provider>
    </React.Fragment>,
    document.querySelector('#root'));
    unregisterServiceWorker();