import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default class CardSidebar extends React.Component{

    constructor(props) {
        super(props);

        this.state ={
            anchor: false
        }
    }

    toggleDrawer = (anchor, open, event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ anchor: open });
    };

    render() {

        return (

            <React.Fragment >
                <Button className='btn' onClick={(e)=>{this.toggleDrawer(this.state.anchor, true, e)}}>Manage</Button>
                <Drawer
                    open={this.state.anchor}
                    onClose={(e)=>{this.toggleDrawer(this.state.anchor, false, e)}}
                >
                    <Box
                        sx={{ width: this.state.anchor === 'top' || this.state.anchor === 'bottom' ? 'auto' : 250 }}
                        role="presentation"
                        onClick={(e)=>{this.toggleDrawer(this.state.anchor, false, e)}}
                        onKeyDown={(e)=>{this.toggleDrawer(this.state.anchor, false, e)}}
                    >
                        <List>
                            <ListItem button component="a" href={"/balance/" + this.props.cardDetails.order_id}>
                                <ListItemText primary="Balance" />
                            </ListItem>

                            <ListItem button component="a" href={"/order-detail/" + this.props.cardDetails.order_id}>
                                <ListItemText primary="Order Details" />
                            </ListItem>

                            <ListItem button component="a" href={"/transaction-history/" + this.props.cardDetails.order_id}>
                                <ListItemText primary="Transaction History" />
                            </ListItem>

                            {/*<ListItem button component="a" href="#">
                                <ListItemText primary="Block" />
                            </ListItem>

                            <ListItem button component="a" href="#">
                                <ListItemText primary="Remove" />
                            </ListItem>*/}
                        </List>
                    </Box>
                </Drawer>
            </React.Fragment>
        );
    }
}
