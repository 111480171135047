import React, { Component } from 'react';
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import Loader from '../../../../components/Loader';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import { Modal } from "react-responsive-modal";
import { Button } from '@material-ui/core';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

export default class TransactionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prepaidOrderDetails: {},
            loader: false,
            open: false
        }
    }


    onOpenModal = () => {
        this.setState({ open: true, loader: true });
        this.props.getPrepaidCardOrderDetails(this.props.orderId)
            .then((res)=>{
                let source = res.data;
                if(source.status){
                    this.setState({
                        prepaidOrderDetails: source.data,
                        loader: false
                    });
                }else{
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })
            .catch((error)=>{
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            });

    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        return (
            <div >
                <div className="odr_detail_btn">
                    <Button className='btn' onClick={()=>{this.onOpenModal()}}>View</Button>
                </div>

                <Modal open={open} onClose={this.onCloseModal}>
                    <div className='ggvoucher'>
                        <h2>Order Details</h2>

                        <div bp="grid">
                            <div bp="12">
                                {
                                    this.state.loader ?
                                        <Loader />
                                    :
                                        <div className='Order_Detail_MDL'>
                                            <ul>
                                                <li>
                                                    <p>Order Id</p>
                                                    <p>{this.state.prepaidOrderDetails.order_id}</p>
                                                </li>

                                                <li>
                                                    <p>Card Name</p>
                                                    <p>{this.state.prepaidOrderDetails.card_name}</p>
                                                </li>

                                                {/*<li>
                                                    <p>Card Type</p>
                                                    <p>{this.state.prepaidOrderDetails.order_id}</p>
                                                </li>*/}

                                                <li>
                                                    <p>Order Status</p>
                                                    <p>{this.state.prepaidOrderDetails.order_status_text}</p>
                                                </li>

                                                <li>
                                                    <p>Send to UPV</p>
                                                    <p>{this.state.prepaidOrderDetails.upv_status_text}</p>
                                                </li>

                                                <li>
                                                    <p>Order Date</p>
                                                    <p>{this.state.prepaidOrderDetails.created_date}</p>
                                                </li>

                                                <li>
                                                    <p>Cashback Type</p>
                                                    <p>{this.state.prepaidOrderDetails.caseback_choice_text}</p>
                                                </li>

                                            </ul>

                                            <div bp="grid 12 text-left">
                                                <h2>Charges</h2>
                                            </div>

                                            <div bp="grid 12">
                                                <ul>
                                                {
                                                    this.state.prepaidOrderDetails && this.state.prepaidOrderDetails.payment_details ?
                                                        this.state.prepaidOrderDetails.payment_details.map((data,key)=>{
                                                            return(
                                                                <li>
                                                                    <p>{data.title}</p>
                                                                    <p>Rs. {data.value}/-</p>
                                                                </li>
                                                            )
                                                        })
                                                    :''

                                                }
                                                </ul>
                                            </div>

                                        </div>
                                }
                            </div>

                        </div>

                    </div>
                </Modal>
            </div>
        );
    }
}