import React, {Component} from 'react';
import { Button } from '@material-ui/core';
import Config from '../../../../Config';

export default class Pricing extends Component {
  constructor(props) {
    super(props);


  }

  render() {

    return (
        <React.Fragment>
          <div className='Prepaid_Pricing'>
            <div className='Pricing_header'>
              <h3>{this.props.cardDetails.categoryName}</h3>
            </div>
            <div className='Pricing_body'>
              <div className='Pricing_body_circle'>
                <h4>Rs.{this.props.cardDetails.card_price}/-</h4>
              </div>
              <div className='Pricing_body_list'>
                <ul>
                  {
                    this.props.cardDetails.text.map((data, key) => {
                      return (
                          <li key={key}><p>{data}</p></li>
                      )
                    })
                  }
                </ul>
                <Button
                    onClick={(e)=>{
                        e.preventDefault();
                        window.location.href=Config.APP_URL+"/checkout/" + this.props.cardDetails.id + '/' + this.props.cardDetails.is_digital;
                    }}
                    className='btn'>Apply Now</Button>
              </div>
            </div>
            <div className='Pricing_footer'></div>
          </div>
        </React.Fragment>
    );
  }
}
