import React, {Component} from 'react';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';
import { PageTitle } from 'gg-react-utilities';

export default class SecondayHeader extends Component{

    componentDidMount(){
    }

    render(){

        return(
          <React.Fragment>
            <div className="pt-3 pb-2 py-4">
                <div className="row bg_white">
                    <div className="col-xl-5 col-xl-5 col-sm-12 d-flex justify-content-start align-items-center">
                        <KeyboardArrowLeftRounded className='icon mt-0' onClick={()=>{
                            this.props.backUrl ?
                                    window.location.href = this.props.backUrl
                                :
                                    this.props.history.goBack()
                        }}/>
                        <PageTitle title={this.props.title+' | Global Garner'} />
                        <h1 className="d-inline-block navbar-title align-middle mt-0 ml-3 ml-s-1 mb-0 header-title">{this.props.title}</h1>
                    </div>
                </div>
            </div>
          </React.Fragment>
        );

    }

}
