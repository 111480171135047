import {connect} from "react-redux";
import {getPrepaidCardOrderDetails} from "../../../../actions/action";
import OrderDetails from '../component/OrderDetail';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getPrepaidCardOrderDetails: (id) =>{
            return getPrepaidCardOrderDetails(id);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)