import { ssoInstance,Auth } from 'gg-react-utilities';
import Config from '../Config';
import _ from 'lodash';

export const getPrepaidCardList = (cardType) => {

    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/get-card/' + cardType);
}

export const getPrepaidCardDetails = (obj) => {

    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/get-card-detail', obj);
}

export const getCheckoutDetails = (obj) => {

    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/checkout', obj);
}


export const beforePayment = (obj) => {
    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/before-payment',obj);
}

export const afterPayment = (obj) => {
    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/after-payment',obj);
}

export const getPrepaidCardOrderList = (page) => {

    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/order-history?page='+page);
}

export const getPrepaidCardOrderDetails = (id) => {

    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/order-view/' + id);
}

export const getMyCardList = () => {
    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/my-card');
}

export const getLoadMoneyCheckoutDetails = (obj) => {

    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/checkout-load-balance', obj);
}

export const loadMoneyBeforePayment = (obj) => {
    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/before-load-balance',obj);
}

export const loadMoneyAfterPayment = (obj) => {
    return ssoInstance.post(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/after-load-balance',obj);
}

export const getTrasactionHistory = (id,page) => {
    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/transaction-history/'+id+'?page='+page);
}

export const upiPaymentCheck = (obj) => {
    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/upi-order-status-check/'+obj.type+'/'+obj.txnId);
}

export const setWaitList = () => {

    return ssoInstance.get(Config.PREPAIDCARD_API_URL + 'user/v1.0.3/prepaid-waitlist');
}