import React, { Component } from 'react';
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import "react-responsive-modal/styles.css";
import style from "blueprint-css/dist/blueprint.css";
import { styles } from "../../../../style/prepaid-card/prepaid_card.css";
import { Button } from '@material-ui/core';
import Loader from '../../../../components/Loader';
import { Modal } from "react-responsive-modal";
import { Checkbox, RadioGroup, Radio, Toaster, Intent, Position } from "@blueprintjs/core";
import TextField from '@mui/material/TextField';
import QRCode from "react-qr-code";
import moment from "moment";
import Config from "../../../../Config";
import {Auth} from "gg-react-utilities";

export default class Checkout_1 extends Component {
    constructor(props) {
        super(props);

        let users = Auth.user();

        this.state = {
            userDetails:users,
            checkoutDetails :[],
            pgDetails:[],
            vouchers:[],
            paymentMethod:"1",
            name:users.fname + " " +users.lname,
            email:users.email,
            mobile:users.mobile,
            open: false,
            upiOpen: false,
            upiURL: '',
            loading:false,
            paymentLoader:false,
            time:"05"
        }
    }
    componentDidMount() {

        let obj = {
            id:this.props.match.params.cardId,
            is_digital:this.props.match.params.cardType,
            upi:this.state.paymentMethod
        }

        this.checkoutAPI(obj);
    }

    checkoutAPI = (obj) => {
        this.props.getCheckoutDetails(obj)
            .then((res) => {

                let resource = res.data;
                if(resource.status === true) {
                    this.setState({
                        checkoutDetails : resource.data,
                        vouchers : resource.data.applied_gg_voucher_id,
                        loading: false,
                        open:false
                    })
                }else{
                    this.setState({
                        loading: false,
                        open:false
                    })
                    Toaster.create({position: Position.TOP}).show({
                        message: resource.message,
                        intent: Intent.DANGER
                    });
                }
            })
            .catch((error)=>{
                this.setState({
                    loading: false,
                    open:false,
                    vouchers:[]
                })
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: error.response.data.message,
                        intent: Intent.DANGER
                    });
                }
            })
    }

    selectedVouchers = (e) => {
        let vouchers = this.state.vouchers;

        if(e.target.checked){
            vouchers.push(e.target.value);
        }else{
            let valueToRemove = e.target.value
            vouchers = vouchers.filter(item => item != valueToRemove)
        }
        this.setState({
            vouchers
        })
    }

    applyGgVouchers = () => {

        if(this.state.vouchers.length == 0){
            Toaster.create({position: Position.TOP}).show({message:"Select GG Voucher.",intent: Intent.DANGER});
            return false;
        }

        let obj = {
            id:this.props.match.params.cardId,
            is_digital:this.props.match.params.cardType,
            applied_voucher: this.state.vouchers,
            upi:this.state.paymentMethod
        }

        this.setState({
            loading:true
        })

        this.checkoutAPI(obj);

    }

    clearGgVouchers = () => {

        if(this.state.checkoutDetails.applied_gg_voucher_id.length == 0){
            Toaster.create({position: Position.TOP}).show({message:"GG Voucher not selected!.",intent: Intent.DANGER});
            return false;
        }

        let obj = {
            id:this.props.match.params.cardId,
            is_digital:this.props.match.params.cardType,
            applied_voucher: [],
            upi:this.state.paymentMethod
        }

        this.setState({
            loading:true
        })

        this.checkoutAPI(obj);

    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onUpiCloseModal = () => {
        this.setState({ upiOpen: false,paymentLoader:false });
    };

    paymentTime = () => {
        let secTimer = setInterval(() => {

            let time = moment(this.state.time, 'mmss').subtract(1, 'seconds').format('mm:ss');
            this.setState({
                time
            })

            if(moment(time, 'mmss').format('ss') % 15 == 0){
                this.PgCheck();
            }

            if(time == '00:00'){
                clearInterval(secTimer);
                window.location.href = Config.APP_URL + "/payment/failed?msg=We are processing your payment request please check back in sometimes!&title=Payment Awaiting!";
            }

        }, 1000)

    }

    PgCheck = () => {

        let obj = {
            type:1,
            txnId:this.state.pgDetails.pg.txnid
        }
        this.props.upiPaymentCheck(obj)
            .then((res)=>{
                let source = res.data;
                if(source.status){
                    if(source.data.payment_status) {
                        window.location.href = Config.APP_URL + "/payment/success?msg=" + source.message + "&title=Payment Success!";
                    }
                }
            })
            .catch((error)=>{
                this.setState({
                    paymentLoader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: error.response.data.message,
                        intent: Intent.DANGER
                    });
                }
            })

    }

    orderPlace = () => {

        if(this.state.name == ''){
            Toaster.create({position: Position.TOP}).show({message:"Customer Name can not be empty.",intent: Intent.DANGER});
            return false;
        }

        if(this.state.mobile.length != 10 || (/^[0-9\b]+$/.test(this.state.mobile)) == false){
            Toaster.create({position: Position.TOP}).show({message:"Invalid Mobile Number.",intent: Intent.DANGER});
            return false;
        }

        if(this.state.email == ''){
            Toaster.create({position: Position.TOP}).show({message:"Email can not be empty.",intent: Intent.DANGER});
            return false;
        }

        if (!(/[^@]+@[^@]+\.[^@]+/.test(String(this.state.email).toLowerCase()))) {
            Toaster.create({position: Position.TOP}).show({message:"Is not a valid email address",intent: Intent.DANGER});
            return false;
        }

        let obj = {
            id:this.state.checkoutDetails.id,
            is_digital:this.state.checkoutDetails.is_digital,
            applied_voucher:this.state.vouchers,
            customer_name:this.state.name,
            customer_email:this.state.email,
            platform:"web",
            customer_mobile: this.state.checkoutDetails.isReloadable == "1" ? this.state.userDetails.mobile : this.state.mobile,
            upi:this.state.paymentMethod
        }

        this.setState({
            paymentLoader:true
        })

        this.props.orderPlace(obj)
            .then((res)=>{
                let source = res;
                if(source.status) {
                    if (this.state.paymentMethod == 1){
                        this.paymentTime()
                        this.setState({
                            pgDetails:source.data,
                            upiOpen: true,
                            upiURL:source.data.upi_url
                        })
                    }
                }
            })
            .catch((error)=>{
                this.setState({
                    paymentLoader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: error.response.data.message,
                        intent: Intent.DANGER
                    });
                }
            })

    }

    render() {
        const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
        const styles = {
            fontFamily: "sans-serif",
            textAlign: "center"
        };
        return (
            <React.Fragment>
                <div className='container'>

                    <PageTitle title="Checkout | Global Garner | Prepaid Card" />
                    <TopBar title="Checkout" {...this.props} />
                    {
                        this.state.checkoutDetails && this.state.checkoutDetails.id ?
                        <div bp="grid padding-bottom mt_60">

                            <div bp="6@lg 6@md">
                                <div className='balance_box checkout'>

                                    <h3>Customer Details</h3>

                                    <div bp="grid">
                                        <div bp="12">
                                                <TextField
                                                    id="standard-basic"
                                                    label="Customer Name."
                                                    variant="standard"
                                                    value={this.state.name}
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            name:e.target.value
                                                        })
                                                    }}
                                                />
                                        </div>
                                    </div>

                                    <div bp="grid">
                                        <div bp="12">
                                                <TextField
                                                    id="standard-basic"
                                                    label="Customer Mobile."
                                                    value={this.state.mobile}
                                                    disabled={this.state.checkoutDetails.isReloadable == "1" ? true : false}
                                                    variant="standard"
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            mobile:e.target.value
                                                        })
                                                    }}/>
                                        </div>
                                    </div>

                                    <div bp="grid">
                                        <div bp="12">

                                                <TextField
                                                    id="standard-basic"
                                                    label="Customer Email."
                                                    variant="standard"
                                                    value={this.state.email}
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            email:e.target.value
                                                        })
                                                    }}/>

                                        </div>
                                    </div>

                                    <div bp="grid text-center mt-3">                                        
                                        <div bp="8" className="balance_btn">
                                            <div style={styles}>
                                                {
                                                    !this.state.paymentLoader ?
                                                        <Button className='btn' onClick={() => {
                                                            this.orderPlace();
                                                        }}>Place Order</Button>
                                                    : <Loader />
                                                }
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div bp="6@lg 6@md ">

                                <div className='balance_box checkout'>
                                    <h3>Payment Summary</h3>
                                    {
                                        !this.state.loading ?
                                        <React.Fragment>
                                        {
                                            this.state.checkoutDetails.payment.map((data,keys)=>{
                                                return(
                                                    <React.Fragment>
                                                        {
                                                            data.key == 'grand_total' ?
                                                                <h3 className='checkout_head'></h3>
                                                                :
                                                                ''
                                                        }
                                                        <div bp="grid">
                                                            {
                                                                data.key == 'default_load_balance' || data.key == 'applied_gg_voucher_amount' ?
                                                                    <React.Fragment>
                                                                        <div bp="9" ><p style={{color: "lime"}}>{data.title}</p></div>
                                                                        <div bp="3" ><p style={{color: "lime"}}>Rs. {data.value}/-</p></div>
                                                                    </React.Fragment>
                                                                :
                                                                    <React.Fragment>
                                                                        <div bp="9" ><p>{data.title}</p></div>
                                                                        <div bp="3" ><p>Rs. {data.value}/-</p></div>
                                                                    </React.Fragment>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })

                                        }
                                        {
                                            <React.Fragment>
                                                <h3 className='checkout_head'>Payment Method</h3>
                                                <RadioGroup
                                                    className='d-flex mt_10'
                                                    selectedValue={this.state.paymentMethod}
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            paymentMethod:e.target.value
                                                        },()=>{
                                                            let obj = {
                                                                id:this.props.match.params.cardId,
                                                                is_digital:this.props.match.params.cardType,
                                                                applied_voucher: this.state.vouchers,
                                                                upi:this.state.paymentMethod
                                                            }

                                                            this.setState({
                                                                loading:true
                                                            })

                                                            this.checkoutAPI(obj);
                                                        })
                                                    }}>
                                                    <Radio style={{fontSize:"1.2rem"}} className="ml-4 mr-4" label={'UPI (PG charges not applicable)'} value={"1"} />
                                                    <Radio style={{fontSize:"1.2rem"}} label={'Debit / Credit / Net Banking / Wallet'} value={"0"} />
                                                </RadioGroup>
                                            </React.Fragment>
                                        }
                                        </React.Fragment>
                                     :
                                        <Loader />
                                    }
                                    <div bp="grid text-center">                                        
                                        <div bp="8" className="balance_btn">
                                            <div style={styles}>
                                                <Button className='btn' onClick={this.onOpenModal}>Apply GG Voucher</Button>

                                                <Modal open={this.state.open} onClose={this.onCloseModal}>
                                                    <div className='ggvoucher'>
                                                        <h2>Apply GG Voucher</h2>

                                                        <div bp="grid">
                                                            <div bp="12">
                                                                <ul className="p-3">
                                                                    {
                                                                        this.state.checkoutDetails.currentActiveVouchers.length ?
                                                                            this.state.checkoutDetails.currentActiveVouchers.map( (data,key) => {
                                                                                return(
                                                                                    <li key={key}>
                                                                                        <div bp="grid">
                                                                                            <div bp="11 text-left">
                                                                                                <p>#{data.id} - valid till {data.valid_till}</p>
                                                                                                <p>Rs. {data.current_balance}/-</p>
                                                                                            </div>
                                                                                            <div bp="1 text-center">
                                                                                                <Checkbox
                                                                                                    checked={this.state.vouchers.find(list => list == data.id)}
                                                                                                    value={data.id}
                                                                                                    onChange={(e)=> {
                                                                                                        this.selectedVouchers(e);
                                                                                                    }}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        :
                                                                            <div bp="grid">
                                                                                <div bp="12@lg 12@md" className="mt-3">
                                                                                    <div className='checkout p-5'>
                                                                                            You have not any GG-Voucher!
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </ul>
                                                            </div>

                                                            <div bp="12 text-center">
                                                                {
                                                                    this.state.checkoutDetails.currentActiveVouchers.length ?
                                                                        !this.state.loading ?
                                                                            <div>
                                                                                <Button className='btn' onClick={() => {
                                                                                    this.applyGgVouchers();
                                                                                }}>Apply</Button>
                                                                                <Button className='btn ml-3' onClick={() => {
                                                                                    this.clearGgVouchers();
                                                                                }}>Clear</Button>
                                                                            </div>
                                                                        :
                                                                            <Loader />
                                                                    :''
                                                                }
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Modal>
                                                <Modal open={this.state.upiOpen} onClose={this.onUpiCloseModal}>
                                                    <div className='ggvoucher'>
                                                        <h2>UPI Payment</h2>

                                                        <div bp="grid">
                                                            <div bp="12">
                                                                <center>
                                                                    <QRCode
                                                                        value={this.state.upiURL}
                                                                        title={this.state.upiURL}
                                                                        level="Q" />
                                                                </center>
                                                            </div>

                                                            <div bp="12 text-center">
                                                                 <h4 style={{color:"red"}}>Make Payment: {this.state.time}</h4>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        :
                        <Loader />
                    }
                </div>


            </React.Fragment>
        );
    }
}