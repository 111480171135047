import React, {Component} from 'react';
import MyCardList from "./container/MyCardList";

export default class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <MyCardList {...this.props}/>
            </React.Fragment>
        );
    }
}