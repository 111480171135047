import React, {Component} from 'react';
import Checkout from "./container/Checkout";

export default class Index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <React.Fragment>
          <Checkout {...this.props}/>
        </React.Fragment>
    );
  }
}