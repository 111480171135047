import {connect} from "react-redux";
import {getMyCardList} from "../../../../actions/action";
import {MyCardListAction} from "../../../../actions";
import OrderHistory from '../component/MyCardLIst';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
        myCardList:state.prepaidCard.myCardList,
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getMyCardList: () =>{
            return getMyCardList()
                .then((res) => {

                    let resource = res.data;

                    if(resource.status === true) {
                        dispatch(MyCardListAction(resource.data))
                    }
                })
                .catch((error)=>{
                    if (401 !== error.response.status) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
                });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)