import React from 'react';

export function MyCards() {
    return (
        <React.Fragment>
            <div className='container mb_30' bp="grid padding-left padding-right">
                <div className='floating-cart'>
                    <a href='/my-card/1' title="My Card" ><i className="fa fa-credit-card"></i></a>
                </div>
            </div>
        </React.Fragment>
    );
}

export function MyOrders() {
  return (
      <React.Fragment>
          <div className='container mb_30' bp="grid padding-left padding-right">
              <div className='floating-order'>
                  <a href='/orders' title="My Order"><i className="fa fa-file-text-o"></i></a>
              </div>
          </div>
      </React.Fragment>
  );
}

export function Home() {
    return (
        <React.Fragment>
            <div className='container mb_30' bp="grid padding-left padding-right">
                <div className='floating-home'>
                    <a href='/home' title="Go To Home"><i className="fa fa-home"></i></a>
                </div>
            </div>
        </React.Fragment>
    );
}