import React, { Component } from 'react'
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import MyCard from './MyCard';
import style from '../../../../style/prepaid-card/prepaid_card.css';
import Loader from '../../../../components/Loader';
import { MyOrders, Home} from '../../../../components/SortCutIcons';

export default class MyCardLIst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardList:[],
            loader:false
        }
    }

    componentDidMount() {
        this.setState({
            loader:true
        })
        this.props.getMyCardList();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        this.setState({
            cardList:nextProps.myCardList,
            loader:false
        })

        if(this.props.match.params.isHome == undefined && nextProps.myCardList.length == 0){
            window.location.href = '/home'
        }

    }

    render() {
        return (
            <React.Fragment>
                <div className='container mb_30' bp="grid">
                    <div bp="12">
                        <PageTitle title="My Cards | Global Garner | Prepaid Card" />
                        <TopBar title="My Cards" {...this.props} />

                        <div bp="grid">
                            {
                                !this.state.loader ?
                                    this.state.cardList && this.state.cardList.length ?
                                        this.state.cardList.map((data,key)=>{
                                            return(
                                                <MyCard cardDetails={data} />
                                            )
                                        })
                                    :
                                        <div bp="12">
                                            <center><b>Card list is empty.</b></center>
                                        </div>

                                :
                                    <div bp="12">
                                        <Loader />
                                    </div>
                            }
                        </div>

                    </div>
                </div>

                <MyOrders />
                <Home />

            </React.Fragment>
        )
    }
}