import {connect} from "react-redux";
import {getLoadMoneyCheckoutDetails, loadMoneyBeforePayment, loadMoneyAfterPayment, upiPaymentCheck} from "../../../../actions/action";
import {MyCardListAction} from "../../../../actions";
import Balance from './../component/Balance';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getLoadMoneyCheckoutDetails: (obj) =>{
            return getLoadMoneyCheckoutDetails(obj);

        },
        orderPlace : (obj) => {

            return loadMoneyBeforePayment(obj)
                .then( (response) => {

                    const sources = response.data;

                    if(sources.status===false){
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: sources.message, intent:Intent.DANGER});

                    }else{

                        if(sources.data.is_upi) {
                            return new Promise((resolve,reject)=>{
                                resolve(sources)
                            });
                        }else if(sources.data.pg.need_payment) {
                            const paymentSignature = sources.data.pg;

                            let form = document.createElement("form");
                            form.setAttribute("method", 'POST');
                            form.setAttribute("action", paymentSignature.url);

                            for (let key in paymentSignature) {

                                let hiddenField = document.createElement("input");
                                hiddenField.setAttribute("type", "hidden");
                                hiddenField.setAttribute("name", key);
                                hiddenField.setAttribute("value", paymentSignature[key]);

                                form.appendChild(hiddenField);

                            }
                            document.body.appendChild(form);
                            form.submit();

                        }else{

                            return loadMoneyAfterPayment(sources.data.pg)
                                .then( (response) => {

                                    const sources = response.data;

                                    if(sources.status===true){
                                        window.location.href = "/payment/success?msg="+sources.message;
                                    }

                                    if(sources.status===false){
                                        window.location.href = "/payment/failed?msg="+sources.message;
                                    }
                                    return new Promise((resolve,reject)=>{
                                        resolve(sources)
                                    });
                                })
                                .catch( (error) => {
                                    if(error){
                                        const sources = error.response.data;

                                        if(sources.errors){
                                            Object.keys(sources.errors).forEach((key)=>{

                                                error.response.data.errors[key].forEach((error)=>{

                                                    window.location.href = "/payment/failed?msg=" + error;

                                                });

                                            });
                                            return new Promise((resolve,reject)=>{
                                                reject(error)
                                            });
                                        }else{
                                            if(sources.status===false){

                                                window.location.href = "/payment/failed?msg=" + sources.message;

                                            }else{

                                                window.location.href = "/payment/failed?msg=" + sources.message;
                                            }
                                            return new Promise((resolve,reject)=>{
                                                reject(sources)
                                            });
                                        }
                                        // dispatch(actions.paymentCatchResponse(sources));
                                    }
                                })
                        }

                    }
                    return new Promise((resolve,reject)=>{
                        resolve(sources)
                    });
                })
                .catch( (error) => {
                    if(error){
                        const sources = error.response.data;

                        if(sources.errors){
                            Object.keys(sources.errors).forEach((key)=>{

                                error.response.data.errors[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });
                            return new Promise((resolve,reject)=>{
                                reject(error)
                            });
                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                            return new Promise((resolve,reject)=>{
                                reject(sources)
                            });
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                })
        },
        upiPaymentCheck: (obj) =>{
            return upiPaymentCheck(obj);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Balance)