import React, { Component } from 'react';
import { PageTitle } from "gg-react-utilities";
import TopBar from "../../../../components/topBar";
import { Button } from '@material-ui/core';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Loader from '../../../../components/Loader';
import { Modal } from "react-responsive-modal";
import { Checkbox, Radio, RadioGroup, Toaster, Intent, Position } from '@blueprintjs/core';
import QRCode from "react-qr-code";
import moment from "moment";
import Config from "../../../../Config";

export default class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
        checkoutDetails:[],
        vouchers:[],
        loadAmount:'',
        is_paymentSummary:false,
        summaryLoader:false,
        ggVoucher:false,
        upiOpen: false,
        upiURL: '',
        time:"05",
        pgDetails:[],
        paymentMethod:"1",
    }
  }

  componentDidMount() {

    let obj ={
        order_id:this.props.match.params.orderId,
        upi:this.state.paymentMethod
    }

    this.getCheckoutDetails(obj);
  }

  getCheckoutDetails = (obj) =>{
    this.props.getLoadMoneyCheckoutDetails(obj)
        .then((res) => {
          let resource = res.data;
          if(resource.status === true) {
              this.setState({
                checkoutDetails:resource.data,
                vouchers:resource.data.applied_voucher,
                summaryLoader:false,
                ggVoucher:false
              })
          }else{
            Toaster.create({position: Position.TOP}).show({
              message: resource.message,
              intent: Intent.DANGER
            });
            this.setState({
              summaryLoader:false
            })
          }
        })
        .catch((error)=>{
          if (401 !== error.response.status) {
            Toaster.create({position: Position.TOP}).show({
              message: error.response.data.message,
              intent: Intent.DANGER
            });
          }
        });
  }

  loadMoneyInCheckout = () => {

    if(this.state.loadAmount <= 0 || (/^[0-9\b]+$/.test(this.state.loadAmount)) == false){
      Toaster.create({position: Position.TOP}).show({message:"Invalid Load Amount.",intent: Intent.DANGER});
      return false;
    }
    if(this.state.loadAmount < this.state.checkoutDetails.minLoadBalance && this.state.loadAmount > this.state.checkoutDetails.maxLoadBalance){
      Toaster.create({position: Position.TOP}).show({message:"Enter Load balance between "+this.state.checkoutDetails.minLoadBalance+" to "+this.state.checkoutDetails.maxLoadBalance,intent: Intent.DANGER});
      return false;
    }

    this.setState({
      is_paymentSummary:true,
      summaryLoader:true
    })

    let obj ={
      order_id:this.props.match.params.orderId,
      amount:this.state.loadAmount,
      upi:this.state.paymentMethod
    }

    this.getCheckoutDetails(obj);
  }

  onOpenModal = () => {
    this.setState({ ggVoucher: true });
  };

  onCloseModal = () => {
    this.setState({ ggVoucher: false });
  };

  onUpiCloseModal = () => {
    this.setState({ upiOpen: false,paymentLoader:false });
  };

  loadAmountUpdate = (amount) => {
    this.setState({
      loadAmount:amount
    })
  }

  selectedVouchers = (e) => {
    let vouchers = this.state.vouchers;

    if(e.target.checked){
      vouchers.push(e.target.value);
    }else{
      let valueToRemove = e.target.value
      vouchers = vouchers.filter(item => item != valueToRemove)
    }
    this.setState({
      vouchers
    })
  }

  applyGgVouchers = () => {

    if(this.state.vouchers.length == 0){
      Toaster.create({position: Position.TOP}).show({message:"Select GG Voucher.",intent: Intent.DANGER});
      return false;
    }

    this.setState({
      summaryLoader:true
    })

    let obj ={
      order_id:this.props.match.params.orderId,
      amount:this.state.loadAmount,
      applied_voucher:this.state.vouchers,
      upi:this.state.paymentMethod
    }

    this.getCheckoutDetails(obj);

  }

  clearGgVouchers = () => {

    if(this.state.checkoutDetails.applied_voucher.length == 0){
      Toaster.create({position: Position.TOP}).show({message:"GG Voucher not selected!.",intent: Intent.DANGER});
      return false;
    }

    this.setState({
      summaryLoader:true
    })

    let obj ={
      order_id:this.props.match.params.orderId,
      amount:this.state.loadAmount,
      applied_voucher:this.state.vouchers,
      upi:this.state.paymentMethod
    }

    this.getCheckoutDetails(obj);

  }

  paymentTime = () => {
    let secTimer = setInterval(() => {

      let time = moment(this.state.time, 'mmss').subtract(1, 'seconds').format('mm:ss');
      this.setState({
        time
      })

      if(this.state.upiOpen == false){
        clearInterval(secTimer);
      }

      if(moment(time, 'mmss').format('ss') % 15 == 0){
        this.PgCheck();
      }

      if(time == '00:00'){
        clearInterval(secTimer);
        window.location.href = Config.APP_URL + "/payment/failed?msg=We are processing your payment request please check back in sometimes!&title=Payment Awaiting!&loadbalance=true&orderid="+this.props.match.params.orderId;
      }
    }, 1000)

  }

  PgCheck = () => {

    let obj = {
      type:0,
      txnId:this.state.pgDetails.pg.txnid
    }
    this.props.upiPaymentCheck(obj)
        .then((res)=>{
          let source = res.data;
          if(source.status){
            if(source.data.payment_status) {
              window.location.href = Config.APP_URL + "/payment/success?msg=" + source.message + "&title=Payment Success!&loadbalance=true&orderid=" + this.props.match.params.orderId;
            }
          }
        })
        .catch((error)=>{
          this.setState({
            paymentLoader:false
          })
          if (error.response && 401 !== error.response.status) {
            Toaster.create({position: Position.TOP}).show({
              message: error.response.data.message,
              intent: Intent.DANGER
            });
          }
        })

  }

  orderplace = () => {
    let obj = {
      order_id:this.props.match.params.orderId,
      amount:this.state.loadAmount,
      applied_voucher:this.state.vouchers,
      platform:"web",
      upi:this.state.paymentMethod
    }

    this.setState({
      paymentLoader:true
    })

    this.props.orderPlace(obj)
        .then((res)=>{
          let source = res;
          if(source.status) {
            if (this.state.paymentMethod == 1){
              this.setState({
                pgDetails:source.data,
                upiOpen: true,
                upiURL:source.data.upi_url
              },()=>{
                this.paymentTime();
              })
            }
          }
        })
        .catch((error)=>{
          this.setState({
            paymentLoader:false
          })
          if (error.response && 401 !== error.response.status) {
            Toaster.create({position: Position.TOP}).show({
              message: error.response.data.message,
              intent: Intent.DANGER
            });
          }
        })
  }

  render() {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center"
    };
    return (
      <React.Fragment>
        <div className='container mb_50' bp="grid">

          <div bp="12">
            <PageTitle title="Balance | Global Garner | Prepaid Card" />
            <TopBar title="Balance" {...this.props} />
          </div>

          <div bp="12">
            <div bp="grid">
            {
              this.state.checkoutDetails && this.state.checkoutDetails.currentBalance ?
                <React.Fragment>
                  <div bp="12" className="pl-2 pt-2 pb-2 border-rounded background-danger"><b>Important Note: </b>Please load the balance only after completing the KYC process else the transaction will not be successful.</div>
                  <div bp={this.state.is_paymentSummary ? "7@lg text-center" : "12 text-center"}>
                    <div className='balance_box'>

                      <div bp="grid">

                        <div bp="12">
                          <div className='balance_innverbox'>
                            <h1>Rs. {this.state.checkoutDetails.currentBalance}/-</h1>
                            <p>Available Balance</p>
                          </div>
                        </div>

                        <div bp="12" className="ml-5 mr-5">

                            <TextField id="standard-basic"
                                       label="Enter Ammount"
                                       variant="standard"
                                       value={this.state.loadAmount}
                                       onChange={(e)=>{
                                         this.setState({
                                           loadAmount:e.target.value
                                         })
                                       }} />

                        </div>

                        <div className='balance_btn' bp={this.state.is_paymentSummary ? "6@lg 6@md 6@sm" : "3@lg 6@md 6@sm"}>
                          <Button className='btn' onClick={()=>{this.loadAmountUpdate(1000)}}><i class="fa fa-plus"></i> 1000</Button>
                        </div>
                        <div className='balance_btn' bp={this.state.is_paymentSummary ? "6@lg 6@md 6@sm" : "3@lg 6@md 6@sm"}>
                          <Button className='btn' onClick={()=>{this.loadAmountUpdate(5000)}}><i class="fa fa-plus"></i> 5000</Button>
                        </div>
                        <div className='balance_btn' bp={this.state.is_paymentSummary ? "6@lg 6@md 6@sm" : "3@lg 6@md 6@sm"}>
                          <Button className='btn' onClick={()=>{this.loadAmountUpdate(10000)}}><i class="fa fa-plus"></i> 10,000</Button>
                        </div>
                        <div className='balance_btn' bp={this.state.is_paymentSummary ? "6@lg 6@md 6@sm" : "3@lg 6@md 6@sm"}>
                          <Button className='btn' onClick={()=>{this.loadAmountUpdate(50000)}}><i class="fa fa-plus"></i> 50,000</Button>
                        </div>

                        <div className='balance_btn2 mb_20' bp="12">
                          <Button className='btn'
                                  onClick={()=>{
                                    this.loadMoneyInCheckout();
                                  }}>Add Money</Button>
                        </div>

                      </div>
                    </div>

                  </div>

                  <div bp="5@lg">
                    {
                      this.state.is_paymentSummary && this.state.checkoutDetails.payment ?
                        <div className='balance_box' style={{minHeight:"408px"}}>
                            <div className='balance_box checkout' style={{border:"none"}}>

                              <h3>Payment Summary</h3>
                              {
                                !this.state.summaryLoader ?
                                <React.Fragment>
                                  {
                                      this.state.checkoutDetails.payment.map((data, keys) => {
                                        return (
                                            <React.Fragment>
                                              {
                                                data.key == 'grand_total' ?
                                                    <h3 className='checkout_head'></h3>
                                                    :
                                                    ''
                                              }
                                              <div bp="grid">
                                                {
                                                  data.key == 'default_load_balance' || data.key == 'applied_gg_voucher_amount' ?
                                                      <React.Fragment>
                                                        <div bp="8"><p style={{color: "green"}}>{data.title}</p></div>
                                                        <div bp="4"><p style={{color: "green"}}>Rs. {data.value}/-</p></div>
                                                      </React.Fragment>
                                                      :
                                                      <React.Fragment>
                                                        <div bp="8"><p>{data.title}</p></div>
                                                        <div bp="4"><p>Rs. {data.value}/-</p></div>
                                                      </React.Fragment>
                                                }

                                              </div>
                                            </React.Fragment>
                                        )
                                      })
                                  }

                                  {
                                    <React.Fragment>
                                      <h3 className='checkout_head'>Payment Method</h3>
                                      <RadioGroup
                                          className='d-flex mt_10'
                                          selectedValue={this.state.paymentMethod}
                                          onChange={(e)=>{
                                            this.setState({
                                              paymentMethod:e.target.value
                                            },()=>{

                                              let obj ={
                                                order_id:this.props.match.params.orderId,
                                                amount:this.state.loadAmount,
                                                applied_voucher:this.state.vouchers,
                                                upi:this.state.paymentMethod
                                              }

                                              this.setState({
                                                summaryLoader:true
                                              })

                                              this.getCheckoutDetails(obj);

                                            })
                                          }}>
                                        <Radio style={{fontSize:"1.2rem"}} className="ml-4 mr-4" label={'UPI'} value={"1"} />
                                        <Radio style={{fontSize:"1.2rem"}} label={'Other'} value={"0"} />
                                      </RadioGroup>
                                    </React.Fragment>
                                  }

                                  <div bp="grid text-center">
                                    <div bp="6@lg" className="balance_btn  mt-2">
                                      {
                                        !this.state.paymentLoader ?
                                            <Button className='btn' onClick={() => {
                                              this.onOpenModal()
                                            }}>Apply GG Voucher</Button>
                                        :''
                                      }
                                    </div>
                                    <div bp="6@lg" className="balance_btn  mt-2">
                                      <div style={styles}>
                                        {
                                          this.state.paymentLoader ?
                                              <Loader />
                                          :
                                              <Button className='btn' onClick={() => {
                                                this.orderplace()
                                              }}>Order Place</Button>
                                        }
                                        <Modal open={this.state.ggVoucher} onClose={this.onCloseModal}>
                                          <div className='ggvoucher'>
                                            <h2>Apply GG Voucher</h2>

                                            <div bp="grid">
                                              <div bp="12">
                                                <ul className="p-3">
                                                  {
                                                    this.state.checkoutDetails.currentActiveVouchers.length ?
                                                        this.state.checkoutDetails.currentActiveVouchers.map((data, key) => {
                                                          return (
                                                              <li key={key}>
                                                                <div bp="grid">
                                                                  <div bp="11 text-left">
                                                                    <p>#{data.id} - valid till {data.valid_till}</p>
                                                                    <p>Rs. {data.current_balance}/-</p>
                                                                  </div>
                                                                  <div bp="1 text-center">
                                                                    <Checkbox
                                                                        checked={this.state.vouchers.find(list => list == data.id)}
                                                                        value={data.id}
                                                                        onChange={(e) => {
                                                                          this.selectedVouchers(e);
                                                                        }}/>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                          )
                                                        })
                                                        :
                                                        <div bp="grid">
                                                          <div bp="12@lg 12@md" className="mt-3">
                                                            <div className='checkout p-5'>
                                                              You have not any GG-Voucher!
                                                            </div>
                                                          </div>
                                                        </div>
                                                  }
                                                </ul>
                                              </div>

                                              <div bp="12 text-center">
                                                {
                                                  this.state.checkoutDetails.currentActiveVouchers.length ?
                                                      !this.state.summaryLoader ?
                                                          <div>
                                                            <Button className='btn' onClick={() => {
                                                              this.applyGgVouchers();
                                                            }}>Apply</Button>
                                                            <Button className='btn ml-3 mt_2' onClick={() => {
                                                              this.clearGgVouchers();
                                                            }}>Clear</Button>
                                                          </div>
                                                          :
                                                          <Loader/>
                                                      : ''
                                                }
                                              </div>

                                            </div>

                                          </div>
                                        </Modal>
                                        <Modal open={this.state.upiOpen} onClose={this.onUpiCloseModal}>
                                          <div className='ggvoucher'>
                                            <h2>UPI Payment</h2>

                                            <div bp="grid">
                                              <div bp="12">
                                                <center>
                                                  <QRCode
                                                      value={this.state.upiURL}
                                                      title={this.state.upiURL}
                                                      level="Q" />
                                                </center>
                                              </div>

                                              <div bp="12 text-center">
                                                <h4 style={{color:"red"}}>Make Payment: {this.state.time}</h4>
                                              </div>

                                            </div>

                                          </div>
                                        </Modal>
                                      </div>
                                    </div>
                                </div>
                              </React.Fragment>
                              :
                                  <center><Loader/></center>
                            }
                          </div>
                        </div>
                      :''
                    }
                  </div>
                </React.Fragment>
              :
                <div bp="12">
                  <Loader />
                </div>
            }
            </div>
          </div>

        </div>
      </React.Fragment>
    )
  }
}